import { ReactNode } from 'react';

import dynamic from 'next/dynamic';
import Head from 'next/head';
import Hubspot from 'shared/components/Hubspot';
import { LatestReviewsProps } from 'shared/components/LatestReviewsV2';
import Widget from 'shared/components/Widget';
import useCanonical from 'shared/hooks/useCanonical';

const Cookies = dynamic(() => import('shared/components/Cookies'), { ssr: false });
const NavBarV2 = dynamic(() => import('shared/components/NavBar-v2'), { ssr: false });
const LatestReviewsV2 = dynamic(() => import('shared/components/LatestReviewsV2'), { ssr: false });
const FooterV2 = dynamic(() => import('shared/components/FooterV2'), { ssr: false });

type PageProps = {
  withNavbar?: boolean;
  withLatestReviews?: boolean;
  withFooter?: boolean;
  withWidget?: boolean;
  withHubspot?: boolean;
  withCookies?: boolean;
  children: ReactNode;
} & Partial<LatestReviewsProps>;

function Page({
  withNavbar = true,
  withLatestReviews = false,
  withFooter = true,
  withWidget = true,
  withHubspot = true,
  withCookies = true,
  reviews = [],
  children: pageContent,
}: PageProps): JSX.Element {
  return (
    <>
      <Head>
        <link rel="canonical" href={useCanonical()} />
      </Head>
      {withNavbar ? <NavBarV2 /> : null}
      {pageContent}
      {withLatestReviews ? <LatestReviewsV2 reviews={reviews} /> : null}
      {withFooter ? <FooterV2 /> : null}
      {withCookies ? <Cookies /> : null}
      {withWidget ? <Widget /> : null}
      {withHubspot ? <Hubspot /> : null}
    </>
  );
}

export default Page;
