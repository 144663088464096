import i18nConfig from 'i18n';

export type Subdomain = {
  language: string;
  subdomain: string;
};

type LanguageSubdomains = {
  [key: string]: Subdomain;
};

export function getNormalizedSubdomains(): LanguageSubdomains {
  return i18nConfig.domains.reduce(
    (acc, { domain, defaultLocale }) => ({
      ...acc,
      [defaultLocale]: {
        language: defaultLocale,
        subdomain: process.env.NEXT_PUBLIC_APP_ENV === 'dev' ? `${domain}:3443` : domain,
      },
    }),
    {},
  );
}

interface TranslatedRoutes {
  [key: string]: string[];
}

const translatedRoutes: TranslatedRoutes = {
  de: ['bewertungen/'],
  bs: ['misljenja/'],
  pl: ['opinie/'],
  bg: ['otzivi/'],
  cs: ['hodnoceni/'],
  el: ['vathmologies/'],
  en: ['reviews/'],
  es: ['opiniones/'],
  et: ['arvustused/'],
  fr: ['avis/'],
  hr: ['recenzije/'],
  hu: ['ertekelesek/'],
  it: ['recensioni/'],
  lt: ['atsiliepimai/'],
  lv: ['atsauksmes/'],
  nl: ['reviews-nl/'],
  no: ['anmeldelser/'],
  pt: ['opinioes/'],
  ro: ['recenzie/'],
  ru: ['otzyvy/'],
  sk: ['hodnoteni/'],
  sl: ['mnenja-in-ocene/'],
  sr: ['recenzije-sr/'],
  sv: ['omdomen/'],
  uk: ['vidhuky/'],
  tr: ['yorumlar/'],
  da: ['anmeldelser-da/'],
  fi: ['arvostelut/'],
};

export function getLanguageHref(subdomain: string, path: string, currentLanguage?: string, language?: string): string {
  let translatedPath;
  const origin = `https://${subdomain}`;
  const pathWithoutQuery = path.split('?')[0];

  if (language && currentLanguage) {
    translatedRoutes[currentLanguage].find((el, index) => {
      if (pathWithoutQuery.includes(el)) {
        translatedPath = pathWithoutQuery.replace(
          translatedRoutes[currentLanguage][index],
          translatedRoutes[language][index],
        );
        return pathWithoutQuery === '/' ? origin : `${origin}${translatedPath}`;
      }
      return null;
    });
  }

  if (translatedPath) {
    return `${origin}${translatedPath}`;
  }

  return pathWithoutQuery === '/' ? origin : `${origin}${pathWithoutQuery}`;
}
