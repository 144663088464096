import { getLanguageHref } from 'shared/utils/subdomains';
import useTranslation from 'shared/hooks/useTranslation';
import { useRouter } from 'next/router';
import useLanguageSubdomains from 'shared/hooks/useLanguageSubdomains';

export default function useCanonical(): string {
  const { lang } = useTranslation();
  const languageSubdomains = useLanguageSubdomains();
  const router = useRouter();

  return getLanguageHref(languageSubdomains.subdomains[lang].subdomain, router.pathname);
}
